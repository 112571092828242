<template>
  <div>
    <div class="w-content" v-if="true">
      <template v-if="true">
        <h2 class="title">{{$t("Confirm_h2_1")}}</h2>
        <div
          class="mb-20 textColorRed"
        >{{$t("ConfirmTrans_p_1")}}</div>
        <div>To Address (Recipient)</div>
        <div class="mb-30">
          <span class="fontBold line_left">{{transactionPackData.ReceiverPublicKey}}</span>
        </div>
        <div>{{$t("ConfirmTrans_p_3")}}</div>
        <div class="mb-30">
          <span class="fontBold line_left">{{transactionPackData.Amount}}</span>
        </div>
        <div>{{$t("ConfirmTrans_p_4")}}</div>
        <div class="mb-30">
          <span class="fontBold line_left">{{transactionPackData.Fee}}</span>
        </div>
        <div>Delegation</div>
        <div class="mb-30">
          <span class="fontBold line_left">{{transactionPackData.DelegateEnable?"Create Delegation":(transactionPackData.DelegateDisable?"Withdraw Delegation":"None")}}</span>
        </div>
        <div>Transaction</div>
        <div class="mb-30">
          <span class="fontBold line_left">
            {&nbsp;Amount: "{{transactionPackData.Amount}}",
            &nbsp;Fee: "{{transactionPackData.Fee}}",
            &nbsp;Source: "{{transactionPackData.PublicKey}}",
            &nbsp;Target: "{{transactionPackData.ReceiverPublicKey}}"}
          </span>
        </div>
        <div>Signature</div>
        <div class="mb-30">
          <span class="fontBold line_left">{{toHexString(signature)}}</span>
        </div>
        <div class="row mb-50">
          <router-link :to="`/${$i18n.locale}/wallet/perform-transaction`" class="link fo_link">{{$t("ConfirmTrans_button_1")}}</router-link>
          <button class="bold-link bttn margin_left_60" @click="sendTransaction">{{$t("ConfirmTrans_button_2")}}</button>
        </div>
      </template>
    </div>
    <div v-else>{{$router.go(-1)}}</div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import instance from "../http";
  import i18n from "@/i18n";

  export default {
  name: "transactionResult",
  data: () => ({
    signature: "",
    transactionSignature: ""
    }),
    mounted() {
      console.log('Mounted');
      this.createSignature();
    },
  methods: {
    createSignature: function() {
      console.log("RESULT PACK TR:");
      //console.log(Base58.decode(this.privateKey));
      console.log(
        this.responseTransactionPack.dataResponse.transactionPackagedStr
      );
      let _signature = nacl.sign.detached(
        Base58.decode(
          this.responseTransactionPack.dataResponse.transactionPackagedStr
        ),
        Base58.decode(this.privateKey)
      );

      // this.signature = signature.join("");
      this.signature = _signature;
      this.transactionSignature = Base58.encode(_signature);
      console.log("signature:");
      console.log(this.signature);
      console.log("signature encoded:");
      console.log(this.transactionSignature);
    },
    toHexString: function(byteArray){
      return Array.prototype.map.call(byteArray, function(byte) {
        return ('0' + (byte & 0xFF).toString(16)).slice(-2);
      }).join('');
    },
    sendTransaction() {


      this.createSignature();

      let postData = this.transactionPackData;
      postData.TransactionSignature = this.transactionSignature;
      console.log("postData:");
      console.log(postData);

      // this.transactionPackData.signature = this.transactionSignature;
      // this.$store.commit("SET_transactionPackData", this.transactionPackData);

      let headers = {
        "Content-Type": "application/json"
      };
      let _this = this;
      new Promise((resolve, reject) => {
        _this.$loading(true);
        instance
          .post("../../api/wallet/executetransaction", postData, { headers })
          .then(response => {
            let result = response.data;
            //console.log(response.data);

            if (result.success) {
              this.$store.commit("SET_transactionCompletedResponse", result);
              this.$router.push({
                path: `/${i18n.locale}/wallet/success-transaction`
              });

            } else {
              alert(result.message);
              this.$loading(false);
            }
          })
          .catch(ex => {
            _this.$loading(false);
            //console.log(ex);
          });
      });
    }
  },
  computed: {
    ...mapState({
      responseTransactionPack: state => state.responseTransactionPack,
      transactionPackData: state => state.transactionPackData,
      privateKey: state => state.privateKey,
      networkAlias: s => s.networkAlias
    })
  },
  created() {
    this.$loading(false);
  },
  destroyed() {}
};
</script>
